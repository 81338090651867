import classes from "./Header.module.css";
import { Screenshots } from "../screenshots/screenshots/Screenshots";

export const Header = (props) => {
  return (

    <header className={classes.Header}>
      <div className='wrapperHeader'>
        <div>
          <div className="col-xs-0 col-md-1" ></div>
          <div className={`d-none d-md-block col-md-3 ${classes.headerInfo}`} >
            <h2>ProFair Games - a rising star in game development</h2>
            <p>offering innovative solutions. We have gaming enthusiasts who use their creativity and imagination in building games to give your visitors an incredible gaming experience.</p>
            <button type="submit" onClick={() => (window.open("https://lobby.profairgames.com/", "_blink"))}>More Games</button>
          </div>
          <div className="col-xs-12 col-md-8" >
            <Screenshots></Screenshots>
          </div>
        </div>
      </div>
    </header >
  )
}

